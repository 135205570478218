<script>
import { fromOplusTime } from '../common/common';
import { timestampSecsFromDate, roundTwoDecimals, roundNum, isZeroDate } from '../js/common';
import { handleSummaryAddButtonClick } from '../js/summary_lib';
import { MeStore } from '../store/v1/meStore';
import { initCellEdit, makeRectangle, addCellData, handleRightClickCell, setSelectedCell, resetSelectedForDrag, setSelectedForDragData, removeCellData, resetSelectCopyOrCut, closeCellEditMenu } from '../summaryTable/tableEditMode'
import Slot from './Slot.vue';

export default {
  props: {
    date: String,
    userId: Number,
    slotProps: Object,
    canEdit: Boolean,
    branchSettings: Object,
    isClosed: Boolean,
    slotMap: Object,

    prevUserId: Number,
    nextUserId: Number,
    prevDate: String,
    nextDate: String,
    meStore: MeStore,
    // used in shift page, `isUsedInShiftPage` is always false in summary page
    isUsedInShiftPage: {
      type: Boolean,
      default: false,
    },
    isStaffLocked: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      slots: [],
    }
  },
  components: {
    Slot,
  },
  inject:[
      'cellEditMode',
  ],
  methods: {
    handleSlotMousedown() {
      if (this.cellEditMode.isOn) {
        return;
      }
    },
    handleMousedown(event) {
      const isCtrlOrCmdPressed = event.ctrlKey || event.metaKey;
      if (this.cellEditMode.isOn) {
        return;
      }

      if (isCtrlOrCmdPressed) {
        initCellEdit()
      }
    },
    handleMouseup() {
      if (!this.cellEditMode.isOn) {
        return;
      }
    },
    handleCellClick() {
      if (this.isClosed || this.cellEditMode.isOn) {
        return;
      }
      const dateUnix = timestampSecsFromDate(new Date(this.date));
      return handleSummaryAddButtonClick(dateUnix, this.userId);
    },
    handleCellRightClick(e) {
      if (this.isUsedInShiftPage) {
        return;
      }
      initCellEdit();
      setSelectedCell(this.cellData);
      handleRightClickCell(e);
    },
    // Edit mode mouse event handlers
    handleSelectCellMouseDown() {
      this.cellEditMode.isMouseDown = true;
      this.cellEditMode.isDragging = false;
      setSelectedForDragData(this.cellData)
    },
    handleSelectCellMouseMove() {
      if (this.cellEditMode.isMouseDown) {
        this.cellEditMode.isDragging = true;
        document.body.style.userSelect = "none"
        if (!this.cellEditMode.isCmdOrMetaKeyPressed) {
          this.cellEditMode.copyCellData = {};
        }
        makeRectangle(this.cellData, this.cellEditMode.selectedCellForDrag)
      }
    },
    handleEditCellRightClick() {
      closeCellEditMenu();
      setSelectedCell(this.cellData);
    },
    handleSelectCellMouseUp(event) {
      closeCellEditMenu();

      const isCtrlOrCmdPressed = event.ctrlKey || event.metaKey;
      const isShiftKeyPressed = event.shiftKey

      if (isCtrlOrCmdPressed && isShiftKeyPressed) {
        makeRectangle(this.cellData, this.cellEditMode.selectedCell);
        return;
      }

      if (this.cellEditMode.isCmdOrMetaKeyPressed) {
        resetSelectCopyOrCut();
        if (this.isSelectedMulti && !this.cellEditMode.isDragging) {
          removeCellData(this.userId, this.date);
        } else {
          addCellData(this.userId, this.date);
        }
        return;
      }

      if (!this.cellEditMode.isDragging) {
        setSelectedCell(this.cellData);
      }
    },
    handleSelectCellMouseEnter() {
      if (this.cellEditMode.isDragging && this.cellEditMode.isMouseDown) {
        addCellData(this.userId, this.date);
      }
    },
    handleSelectCellMouseLeave() {
      if (this.cellEditMode.isDragging && this.cellEditMode.isMouseDown) {
      } else {
        resetSelectedForDrag(this.cellData)
      }
    },
    updateSlots(slots) {
      if (slots) {
        this.slots = this.sortSlotsByFrom(slots);
      } else {
        this.slots = [];
      }
    },
    sortSlotsByFrom(slots) {
      const ids = Object.keys(slots);
      if (ids.length) {
        ids.sort((a, b) => new Date(this.slotMap[a].From) - new Date(this.slotMap[b].From));
      }
      return ids;
    },
    addBackGroundOnTap(event) {
      if (this.isUsedInShiftPage) {
        return;
      }
      event.currentTarget.classList.add("tapped");
    },
    removeBackgroundOnTap(event) {
      const target = event.currentTarget
      setTimeout(() => {
        target.classList.remove("tapped");
      }, 100)
    },
    filterSlotForCopyOrCut() {
      const slotIds = [];
      this.slots.forEach(slotId => {
        const slot = this.slotMap[slotId];
        if (slot.ClonedSlotKey || slot.IsHelp) {
          return
        }
        if (slot.LinkNext) {
          slotIds.push(slot.LinkNext)
        }
        if (slot.LinkPrev) {
          slotIds.push(slot.LinkPrev)
        }
        slotIds.push(slot.ID);
      })
      if (this.cellState) {
        this.cellState["slotIds"] = [...slotIds]
      }
      if (this.cellEditMode.selectedCell) {
        this.cellEditMode.selectedCell["slotIds"] = [...slotIds]
      }
    },
    filterSlotsForDisabledCell() {
      return this.slots.reduce((filtered, slotId) => {
        const slot = this.slotMap?.[slotId];

        // Slots with ManagedSlotKey indicates they are Requested slots (slot created by non-manager level)
        if (!slot || slot.ManagedSlotKey) {
          return filtered;
        } 

        // Slots with RequestedSlotKey are slots that is created by manager in response to staff's slot (Requested by Manager)
        const isRequestedByManager = slot.RequestedSlotKey && slot.RequestedSlotID !== null;
        const isCreatedByCurrentUser = slot.CreatedBy === parseInt(this.userId);

        if (isRequestedByManager || isCreatedByCurrentUser) {
          const id = slot.RequestedSlotKey ? slot.RequestedSlotID : slotId;
          if (id) {
            filtered.push(id);
          }
        }

        return filtered;
      }, []);
    },
  },
  computed: {
    isCellLocked() {
      if (!this.branchSettings.shiftLockDate) {
        return false;
      }
      const shiftLockDate = new Date(this.branchSettings.shiftLockDate);
      return !isZeroDate(new Date(this.branchSettings.shiftLockDate)) && fromOplusTime(shiftLockDate) <= fromOplusTime(new Date(this.date));
    },
    isCellClosed() {
      const cellDay = fromOplusTime(new Date(this.date)).getDay()
      return this.branchSettings.closedWeekdays[cellDay]
    },
    isCellDisabled() {
      return this.isCellLocked || this.isCellClosed
    },
    computedSlotIds() {
      if (this.meStore.getIsLeader() || !(this.isCellDisabled)) {
        return this.slots;
      }

      if (this.branchSettings.stackedRequest) {
        // On the shift page, if stackedRequest is enabled and cell is disabled, 
        // filter slots to only show shifts the staff submitted
        if (this.isUsedInShiftPage) {
          return this.filterSlotsForDisabledCell();
        }

        return this.slots;
      }

      return []; // Cell disabled and no stacked request
    },
    cellData() {
      return {
        id: `cell-${this.userId}-${this.date}`,
        userId: this.userId.toString(),
        date: this.date,
        slotIds: [...this.slots]
      }
    },
    cellState() {
      return this.cellEditMode.copyCellData?.[this.userId]?.[this.date];
    },
    singleCellState() {
      return this.cellEditMode.singleCopyCellData?.[this.userId]?.[this.date];
    },
    isTopCellSelected() {
      return Boolean(this.cellEditMode.copyCellData?.[this.prevUserId]?.[this.date]?.isSelectedForCopy || this.cellEditMode.copyCellData?.[this.prevUserId]?.[this.date]?.isSelectedForCut)
    },
    isBottomCellSelected() {
      return Boolean(this.cellEditMode.copyCellData?.[this.nextUserId]?.[this.date]?.isSelectedForCopy || this.cellEditMode.copyCellData?.[this.nextUserId]?.[this.date]?.isSelectedForCut)
    },
    isLeftCellSelected() {
      return Boolean(this.cellEditMode.copyCellData?.[this.userId]?.[this.prevDate]?.isSelectedForCopy || this.cellEditMode.copyCellData?.[this.userId]?.[this.prevDate]?.isSelectedForCut)
    },
    isRightCellSelected() {
      return Boolean(this.cellEditMode.copyCellData?.[this.userId]?.[this.nextDate]?.isSelectedForCopy || this.cellEditMode.copyCellData?.[this.userId]?.[this.nextDate]?.isSelectedForCut)
    },
    isSelectedMulti() {
      const isSelected = Boolean(this.cellState);
      if (isSelected) {
        this.cellState["slotIds"] = [];
      }
      return isSelected;
    },
    isSelectedForCopy() {
      const isOn = Boolean(this.cellState?.isSelectedForCopy || this.singleCellState?.isSelectedForCopy);
      if (isOn) {
        this.filterSlotForCopyOrCut();
      }
      return isOn;
    },
    isSelectedForCut() {
      const isOn = Boolean(this.cellState?.isSelectedForCut  || this.singleCellState?.isSelectedForCut);
      if (isOn) {
        this.filterSlotForCopyOrCut();
      }
      return isOn;
    },
    isCellSelected() {
      return this.cellEditMode.selectedCell.id === this.cellData.id;
    },
    copyCellClass() {
      return {
        "multi-selected-cell": this.isSelectedMulti,
        "selected-cell": this.isCellSelected,
        "dashed": this.isSelectedForCopy,
        "dashed-cut": this.isSelectedForCut,
        "is-top-selected": this.isTopCellSelected,
        "is-bottom-selected": this.isBottomCellSelected,
        "is-left-selected": this.isLeftCellSelected,
        "is-right-selected": this.isRightCellSelected,
      }
    },
  },
  created() {
    this.updateSlots(this.slotProps);
  },
  mounted() {
  },
  updated() {
  },
  beforeUnmount() {
  },
}
</script>

<template>
  <!-- For Cell Edit mode -->
  <div v-if="cellEditMode.isOn" class="edit-mode-overlay c-hand">
    <div
      class="edit-mode-cell"
      :class="copyCellClass"
      @mousedown="handleSelectCellMouseDown"
      @mousemove="handleSelectCellMouseMove"
      @mouseup.left="handleSelectCellMouseUp"
      @click.right.prevent="handleEditCellRightClick"
      @mouseenter="handleSelectCellMouseEnter"
      @mouseleave="handleSelectCellMouseLeave"
      >
    </div>
  </div>

  <div v-if="isUsedInShiftPage && isCellLocked" class="text-center w-100">
    <i class="zmdi zmdi-lock zmdi-hc-lg text-gray py-10"></i>
  </div>
  <div v-else-if="isCellClosed" class="text-center w-100">
    <i class="zmdi zmdi-close zmdi-hc-lg text-gray py-10"></i>
  </div>
  <!-- Default Cell state -->
  <div :id="`date-${userId}_${date}`"
    class="vue-cell-dropzone"
    :class="{ 'pointer-event-none': cellEditMode.isOn, 'is-shift-page': isUsedInShiftPage }"
    @mouseup.left="handleMouseup"
    @mousedown.left="handleMousedown"
    @click.left="handleCellClick"
    @click.right.prevent="handleCellRightClick"
    @focus.stop="addBackGroundOnTap"
    @blur="removeBackgroundOnTap"
    @mouseleave="removeBackgroundOnTap"
    :data-date="date"
    :data-user="userId"
    tabindex="0"
  >
    <div v-if="isClosed" class="text-center close-btn-wrapper">
      <i class="zmdi zmdi-close zmdi-hc-lg text-gray"></i>
    </div>
    <Slot
      v-for="id in computedSlotIds"
      :key="`${id}_${date}`"
      :slot="slotMap[id]"
      :repeat-date="slotMap[id].Repeat ? new Date(date) : null"
      :can-edit="canEdit"
      :branch-settings="branchSettings"
      :date="this.date"
      :me-store="meStore"
      :is-cell-disabled="isCellDisabled"
      :is-used-in-shift-page="isUsedInShiftPage"
      @requires-recalc="(e) => addStatDiffsToColumnAndRender(e)"
      @click.stop
      @mousedown="handleSlotMousedown"
    />
    <div v-if="!isCellClosed && !isUsedInShiftPage" :id="`add-${userId}_${date}`">
      <span class="chip add-button" @click.stop="handleCellClick">
        <i class="icon icon-plus"></i>
      </span>
    </div>
  </div>
</template>
<style scoped>
  .w-100 {
    width: 100%;
  }

  .edit-mode-overlay,
  .vue-cell-dropzone,
  .edit-mode-cell {
    width: 100%;
    height: 100%;
  }

  .edit-mode-cell {
    border: .1rem solid transparent;
  }

  .vue-cell-dropzone.tapped {
     background-color: #61b0e270;
     overflow: hidden;
  }

  .tapped {
    overflow: hidden;
  }

  .edit-mode-overlay {
    position: absolute;
    top: 0;
  }

  .close-btn-wrapper {
    width: 100%;
  }

  .multi-selected-cell {
    border-color: transparent;
		background-color: #93B4DF40;
  }

  .selected-cell {
    outline: .1rem solid #2798ff;
  }

  .dashed {
    border: .1rem dashed #39AEDF;
  }

  .dashed-cut {
    border: .1rem dashed #D94926;
  }

  .multi-selected-cell.is-top-selected {
    border-top: none;
  }

  .multi-selected-cell.is-bottom-selected {
    border-bottom: none;
  }

  .multi-selected-cell.is-left-selected {
    border-left: none;
  }

  .multi-selected-cell.is-right-selected {
    border-right: none;
  }

  .is-shift-page {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
</style>
